<template>
  <nav class="navbar container" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
      <a class="navbar-item" @click="toHome">
        <strong class="is-size-4">Assistant Jumbo</strong>
      </a>
      <a role="button" class="navbar-burger burger" @click="showNav=!showNav" :class="{ 'is-active': showNav }">
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>
    <div id="navbar" class="navbar-menu" :class="{ 'is-active': showNav }">
      <div class="navbar-start">
        <a @click="toHome" class="navbar-item" v-tooltip="$t('nav.home')">
          <font-awesome-icon icon="home" />
        </a>
        <a @click="toAbout" class="navbar-item" v-tooltip="$t('nav.about')">
          <font-awesome-icon icon="info-circle" />
        </a>
      </div>
      <div class="navbar-end">
        <div class="navbar-item">
          <div class="buttons">
            <a v-if="isAuthenticated" class="button is-info" @click="toProfile" v-tooltip="$t('nav.profile')">
              <font-awesome-icon icon="user" />
              </a>
            <a v-if="!isAuthenticated" class="button is-info" href="/login" v-tooltip="$t('nav.login')">
              <font-awesome-icon icon="sign-in-alt" />
            </a>
            <a v-if="isAuthenticated" class="button is-dark" @click="logout" v-tooltip="$t('nav.logout')">
              <font-awesome-icon icon="sign-out-alt" />
            </a>
            <LocaleSwitcher />
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>
<script>
import router from "@/router";
import LocaleSwitcher from "@/components/partials/LocaleSwitcher";


export default {
  name: 'Nav',
  components: {LocaleSwitcher},
  data() {
    return {
      isAuthenticated: false,
      showNav: false
    }
  },

  methods: {
    setLocale: function(locale) {
      this.$i18n.locale = locale
    },
    logout: function() {
      router.app.$cookies.remove("$t")
      router.app.$cookies.remove("$uid")
      this.isAuthenticated = false
      this.$store.commit("setLoggedInUser", {})
      this.$store.commit("setAccount", null)
      router.push("/")
    },
    toProfile: function() {
      router.push("/profile")
    },
    toHome: function() {
      router.push("/")
    },
    toAbout: function() {
      router.push("/about")
    }
  },
  created() {
    const token = router.app.$cookies.get("$t")
    if (token != null) {
      this.isAuthenticated = true
    } else {
      this.isAuthenticated = false
    }
  },
  mounted() {
    this.$root.$on('updateAuth', ()=>{
      this.isAuthenticated = true
    })
  }
}
</script>
<style lang="scss" scoped>
nav {
  margin-top: 25px;
  margin-bottom: 30px;
a {
  font-weight: bold;
  color: #2c3e50;
&.router-link-exact-active {
   color: #d88d00;
 }
}
}
</style>
