<template>
  <div class="home">
    <section class="hero is-dark">
      <div class="hero-body">
        <div class="container">
          <h1 class="title">
            {{ $t("home.welcome") }}
          </h1>
          <h2 class="subtitle">
            {{ $t("home.subtitle")}}
          </h2>
          <div class="button-block">
            <button class="button is-xl is-dark">{{ $t("home.greeting") }} {{ user.displayName }}</button>
          </div>
        </div>
      </div>
    </section>
    <AppList />
  </div>
</template>

<script>
import AppList from '../components/AppList'
import {UserClient} from "@/pb/user_service_grpc_web_pb";
import {GetUserRequest} from "@/pb/user_service_pb";
import common from "@/utility/common"

export default {
  name: 'Home',
  components: {
    AppList
  },
  data() {
    return {
      user: {}
    }
  },
  mounted() {
    let uid = this.$cookies.get("$uid")
    let token = this.$cookies.get("$t")

    if (this.$store.getters.isLogIn) {
      this.user = this.$store.getters.getUser
      if (this.user.email == "" || this.user.email == undefined || this.user.email == null) {
        let userEmail = this.CryptoJS.AES.decrypt(
            uid, "jumbosecret").toString(this.CryptoJS.enc.Utf8);

        let metadata = {'authorization': token}
        this.client = new UserClient(common.SECURE_HOST, null, {
          "grpc.max_receive_message_length": common.MESSAGE_LENGTH,
          "grpc.max_send_message_length": common.MESSAGE_LENGTH
        })
        let getUserRequest = new GetUserRequest()
        getUserRequest.setEmail(userEmail)
        this.client.getUser(getUserRequest, metadata, (err, response)=>{
          if (err != null) {
            alert("Failed to get user: " + err)
            return
          }
          if (response != null) {
            this.user = response.toObject().usersList[0]
            this.$store.commit('setLoggedInUser', this.user)
            this.$root.$emit('updateAuth', true)
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.hero {
  text-align: center;
  background-image: url('../assets/IMG_background.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 400px;
}
.hero-body .title {
  text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.6);
  padding: 40px 0 20px 0;
  font-size: 60px;
}
.subtitle {
  text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.7);
  font-size: 30px;
}
.button-block {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  position: absolute;
  bottom: -150px;
.button {
  margin-right: 50px;
  padding-left: 50px;
  padding-right: 50px;
}
.welcome {
  width: 400px;
  padding: 10px;
  margin-left: auto;
  margin-right: auto;
}
}
.is-xl {
  font-size: 1.7rem;
}
</style>