<template>
  <div class="events container">
    <h2 class="subtitle is-3">
      {{ $t("appList.reminder") }}
    </h2>
    <div class="columns is-multiline">
      <div v-for="event in events" :event="event" :key="event.id" class="column is-one-quarter">
        <router-link :to="'/' + event.alias" v-tooltip="$t('appList.click')">
          <AppCard :event="event" />
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import AppCard from '../components/AppCard';

export default {
  name: 'AppList',
  components : {
    AppCard
  },
  data () {
    return {
      event: {},
      events: [
        {
          id: 1,
          name: this.$t("appList.c-name"),
          category: 'Security',
          description: 'Web tool to save and show password for online accounts or other access control',
          featuredImage: '/img/CredentialManager.png',
          images: [
            'https://placekitten.com/500/500',
            'https://placekitten.com/500/500',
            'https://placekitten.com/500/500',
          ],
          platform: 'all',
          date: '2021-09-23',
          time: '11:30',
          alias: 'credential'
        },
        {
          id: 2,
          name: this.$t("appList.s-name"),
          category: 'Productivity',
          description: 'Web tool to record summary of daily work',
          featuredImage: '/img/Snippet.png',
          images: [
            'https://placekitten.com/500/500',
            'https://placekitten.com/500/500',
            'https://placekitten.com/500/500',
          ],
          platform: 'all',
          date: '2021-09-27',
          time: '11:30',
          alias: 'journal'
        },
        /*
        {
          id: 2,
          name: 'Stock Post',
          category: 'Finance',
          description: 'web tool to show stock information',
          featuredImage: 'https://placekitten.com/500/500',
          images: [
            'https://placekitten.com/500/500',
            'https://placekitten.com/500/500',
            'https://placekitten.com/500/500',
          ],
          platform: 'windows',
          date: '2020-10-08',
          time: '11:30',
          alias: 'stockpost'
        },
        {
          id: 3,
          name: 'URL shortener',
          category: 'Tools',
          description: 'Shorten long url for easy transfer',
          featuredImage: 'https://placekitten.com/500/500',
          images: [
            'https://placekitten.com/500/500'
          ],
          platform: 'All',
          date: '2021-08-09',
          time: '12:00',
          alias: 'shortener'
        },
        */

      ]
    }
  }
}
</script>
<style lang="scss" scoped>
.events {
  margin-top: 100px;
  text-align: center;
}
</style>