/**
 * @fileoverview gRPC-Web generated client stub for pb
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var model_pb = require('./model_pb.js')
const proto = {};
proto.pb = require('./user_service_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.pb.UserClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.pb.UserPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.PingUserMessage,
 *   !proto.pb.PingUserMessage>}
 */
const methodDescriptor_User_PingUser = new grpc.web.MethodDescriptor(
  '/pb.User/PingUser',
  grpc.web.MethodType.UNARY,
  proto.pb.PingUserMessage,
  proto.pb.PingUserMessage,
  /**
   * @param {!proto.pb.PingUserMessage} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pb.PingUserMessage.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pb.PingUserMessage,
 *   !proto.pb.PingUserMessage>}
 */
const methodInfo_User_PingUser = new grpc.web.AbstractClientBase.MethodInfo(
  proto.pb.PingUserMessage,
  /**
   * @param {!proto.pb.PingUserMessage} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pb.PingUserMessage.deserializeBinary
);


/**
 * @param {!proto.pb.PingUserMessage} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pb.PingUserMessage)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.PingUserMessage>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.UserClient.prototype.pingUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pb.User/PingUser',
      request,
      metadata || {},
      methodDescriptor_User_PingUser,
      callback);
};


/**
 * @param {!proto.pb.PingUserMessage} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.PingUserMessage>}
 *     Promise that resolves to the response
 */
proto.pb.UserPromiseClient.prototype.pingUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pb.User/PingUser',
      request,
      metadata || {},
      methodDescriptor_User_PingUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.AddUserRequest,
 *   !proto.pb.AddUserResponse>}
 */
const methodDescriptor_User_AddUser = new grpc.web.MethodDescriptor(
  '/pb.User/AddUser',
  grpc.web.MethodType.UNARY,
  proto.pb.AddUserRequest,
  proto.pb.AddUserResponse,
  /**
   * @param {!proto.pb.AddUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pb.AddUserResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pb.AddUserRequest,
 *   !proto.pb.AddUserResponse>}
 */
const methodInfo_User_AddUser = new grpc.web.AbstractClientBase.MethodInfo(
  proto.pb.AddUserResponse,
  /**
   * @param {!proto.pb.AddUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pb.AddUserResponse.deserializeBinary
);


/**
 * @param {!proto.pb.AddUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pb.AddUserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.AddUserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.UserClient.prototype.addUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pb.User/AddUser',
      request,
      metadata || {},
      methodDescriptor_User_AddUser,
      callback);
};


/**
 * @param {!proto.pb.AddUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.AddUserResponse>}
 *     Promise that resolves to the response
 */
proto.pb.UserPromiseClient.prototype.addUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pb.User/AddUser',
      request,
      metadata || {},
      methodDescriptor_User_AddUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.GetUserRequest,
 *   !proto.pb.GetUserResponse>}
 */
const methodDescriptor_User_GetUser = new grpc.web.MethodDescriptor(
  '/pb.User/GetUser',
  grpc.web.MethodType.UNARY,
  proto.pb.GetUserRequest,
  proto.pb.GetUserResponse,
  /**
   * @param {!proto.pb.GetUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pb.GetUserResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pb.GetUserRequest,
 *   !proto.pb.GetUserResponse>}
 */
const methodInfo_User_GetUser = new grpc.web.AbstractClientBase.MethodInfo(
  proto.pb.GetUserResponse,
  /**
   * @param {!proto.pb.GetUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pb.GetUserResponse.deserializeBinary
);


/**
 * @param {!proto.pb.GetUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pb.GetUserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.GetUserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.UserClient.prototype.getUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pb.User/GetUser',
      request,
      metadata || {},
      methodDescriptor_User_GetUser,
      callback);
};


/**
 * @param {!proto.pb.GetUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.GetUserResponse>}
 *     Promise that resolves to the response
 */
proto.pb.UserPromiseClient.prototype.getUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pb.User/GetUser',
      request,
      metadata || {},
      methodDescriptor_User_GetUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.UpdateUserRequest,
 *   !proto.pb.UpdateUserResponse>}
 */
const methodDescriptor_User_UpdateUser = new grpc.web.MethodDescriptor(
  '/pb.User/UpdateUser',
  grpc.web.MethodType.UNARY,
  proto.pb.UpdateUserRequest,
  proto.pb.UpdateUserResponse,
  /**
   * @param {!proto.pb.UpdateUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pb.UpdateUserResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pb.UpdateUserRequest,
 *   !proto.pb.UpdateUserResponse>}
 */
const methodInfo_User_UpdateUser = new grpc.web.AbstractClientBase.MethodInfo(
  proto.pb.UpdateUserResponse,
  /**
   * @param {!proto.pb.UpdateUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pb.UpdateUserResponse.deserializeBinary
);


/**
 * @param {!proto.pb.UpdateUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pb.UpdateUserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.UpdateUserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.UserClient.prototype.updateUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pb.User/UpdateUser',
      request,
      metadata || {},
      methodDescriptor_User_UpdateUser,
      callback);
};


/**
 * @param {!proto.pb.UpdateUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.UpdateUserResponse>}
 *     Promise that resolves to the response
 */
proto.pb.UserPromiseClient.prototype.updateUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pb.User/UpdateUser',
      request,
      metadata || {},
      methodDescriptor_User_UpdateUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.DeleteUserRequest,
 *   !proto.pb.DeleteUserResponse>}
 */
const methodDescriptor_User_DeleteUser = new grpc.web.MethodDescriptor(
  '/pb.User/DeleteUser',
  grpc.web.MethodType.UNARY,
  proto.pb.DeleteUserRequest,
  proto.pb.DeleteUserResponse,
  /**
   * @param {!proto.pb.DeleteUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pb.DeleteUserResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pb.DeleteUserRequest,
 *   !proto.pb.DeleteUserResponse>}
 */
const methodInfo_User_DeleteUser = new grpc.web.AbstractClientBase.MethodInfo(
  proto.pb.DeleteUserResponse,
  /**
   * @param {!proto.pb.DeleteUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pb.DeleteUserResponse.deserializeBinary
);


/**
 * @param {!proto.pb.DeleteUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pb.DeleteUserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.DeleteUserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.UserClient.prototype.deleteUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pb.User/DeleteUser',
      request,
      metadata || {},
      methodDescriptor_User_DeleteUser,
      callback);
};


/**
 * @param {!proto.pb.DeleteUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.DeleteUserResponse>}
 *     Promise that resolves to the response
 */
proto.pb.UserPromiseClient.prototype.deleteUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pb.User/DeleteUser',
      request,
      metadata || {},
      methodDescriptor_User_DeleteUser);
};


module.exports = proto.pb;

