import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/About.vue')
  },
  {
    path: '/app/:id',
    name: 'appSingle',
    component: () => import('../views/AppSingle.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/journal',
    name: 'journal',
    component: () => import('../views/Snippet.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/credential',
    name: 'credential',
    component: () => import('../views/Credential'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/credential/manage/:account',
    name: 'credential_manage',
    component: () => import('../views/CredentialManage.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/credential/manage',
    name: 'credential_add',
    component: () => import('../views/CredentialManage.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('../views/Profile.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/Register.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routes
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)
  const token = router.app.$cookies.get("$t")

  if (requiresAuth && token === null) {
    next({path: '/login', query: {redirect: to.fullPath}})
  } else {
    next()
  }
})

export default router