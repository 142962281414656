<template>
  <footer class="footer">
    <div class="content has-text-centered">
      <span>&copy; jumbo81.com 2021
        <a href="mailto: zhengbo.zhou@gmail.com" target="_blank" v-tooltip="'Contact'">
          <font-awesome-icon icon="envelope" />
        </a>
      </span>
    </div>
  </footer>
</template>
<script>
export default {
  name: "Footer"
}
</script>