import Vue from 'vue'
import Vuex from 'vuex'
import VueCookies from 'vue-cookies'


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: {},
    token: VueCookies.get('$t') || '',
    account: {},
  },
  mutations: {
    setLoggedInUser(state, user) {
      state.user = user
    },
    setAccount(state, account) {
      state.account = account
    }
  },
  getters: {
    getUser: state => {
      return state.user
    },
    isLogIn: state => {
      state.token = VueCookies.get('$t')
      return state.token != "" && state.token != null && state.token != undefined
    },
    getAccount: state => {
      return state.account
    },
    getToken: state => {
      return state.token
    }
  },
  actions: {
  },
  modules: {
  }
})
