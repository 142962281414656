import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from "./i18n"
import 'bulma/css/bulma.css'
import VueCookies from 'vue-cookies'
import VueCryptojs from 'vue-cryptojs'
import VTooltip from 'v-tooltip'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faEraser, faHome, faInfoCircle, faUser, faSignOutAlt, faPlusSquare, faSave, faTrashAlt, faPlus, faMinus, faEnvelope, faSignInAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faHome)
library.add(faInfoCircle)
library.add(faUser)
library.add(faSignOutAlt)
library.add(faPlusSquare)
library.add(faSave)
library.add(faTrashAlt)
library.add(faPlus)
library.add(faMinus)
library.add(faEnvelope)
library.add(faSignInAlt)
library.add(faEraser)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false

Vue.use(VueCookies)
Vue.use(VueCryptojs)
Vue.use(VTooltip)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
